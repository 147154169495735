import { GEO_NAMES } from '@bridebook/toolbox/src/search-suppliers/location-helpers';
import { CustomAutocompletePrediction, GooglePlace } from '../types';

/**
 * User types in the location search input and selects an item. Details about the location
 * passed here to extract text that will be placed in the input.
 *
 * The function transforms Google Place object to text:
 * `{
 *   address_components: [
 *     {
 *       long_name: 'Hauts-de-France',
 *       short_name: 'Hauts-de-France',
 *       types: ['administrative_area_level_1', 'political'],
 *     },
 *     {
 *       long_name: 'France',
 *       short_name: 'FR',
 *       types: ['country', 'political'],
 *     },
 *   ],
 *   formatted_address: 'Hauts-de-France, France',
 *   name: 'Hauts-de-France',
 *   types: ['administrative_area_level_1', 'political'],
 * }`
 *
 * results in placing `Hauts-de-France` text to the input.
 * @param place
 */
export function extractPlaceName(place: GooglePlace): string {
  let placeName = place.name;
  if ((place as CustomAutocompletePrediction).area) {
    return (place as CustomAutocompletePrediction).area?.replace(/,/g, '').trim() || '';
  }
  const country = place.address_components?.find(isCountry);
  if (
    place.vicinity &&
    place.vicinity !== place.name &&
    place.vicinity !== (country?.long_name || GEO_NAMES.UK)
  ) {
    placeName = `${place.name} ${place.vicinity}`;
  } else {
    const town = place.address_components?.find(isTown);
    if (town) {
      if (place.name !== town.long_name) {
        placeName = `${place.name} ${town.long_name}`;
      }
    } else {
      placeName = getAdminAreaName(place.address_components) || place.formatted_address;
    }
  }
  return placeName?.replace(/,/g, '').trim() || '';
}

const getAdminAreaName = (addressComponents: GooglePlace['address_components']) => {
  const colloquialArea = addressComponents?.find(isColloquialArea);
  const adminArea2 = addressComponents?.find(isAdminArea(2));
  const adminArea1 = addressComponents?.find(isAdminArea(1));

  if (colloquialArea?.long_name) {
    return colloquialArea?.long_name;
  }
  if (adminArea2?.long_name) {
    return adminArea2.long_name;
  }
  if (adminArea1?.long_name) {
    // IE business decision to have "Co. Dublin" instead of "County Dublin". It works consistently with Google API
    return adminArea1.long_name.replace('County', 'Co.');
  }
};

const isTown = (comp: { types: string[] }) =>
  comp.types?.includes('postal_town') || comp.types?.includes('locality');

const isCountry = (comp: { types: string[] }) =>
  comp.types?.includes('country') && comp.types?.includes('political');

const isColloquialArea = (comp: { types: string[] }) =>
  comp.types?.includes('colloquial_area') && comp.types?.includes('political');

const isAdminArea = (level: 1 | 2) => (comp: { types: string[] }) =>
  comp.types?.includes(`administrative_area_level_${level}`);
